import * as React from "react";
import Layout from "../components/layout";
import Childhood from "../components/purpose/childhood";
import Cycle from "../components/purpose/cycle";
import Header from "../components/purpose/header";
import Mission from "../components/purpose/mission";
import Montessori from "../components/purpose/montessori";

const Purpose = () => {
  return (
    <Layout pageTitle=" School Philosophy/Mission - Forest Grove Montessori Pre-School">
      <Header />
      <Mission />
      <Montessori />
      <Childhood />
      <Cycle />
    </Layout>
  );
};

export default Purpose;
