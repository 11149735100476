import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const Cycle = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cycle.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div className="max-w-screen-xl mx-auto pb-12 lg:pb-24 px-4 font-light">
      <div className="grid items-center grid-cols-1 lg:grid-cols-12">
        <div className="lg:col-span-3">
          <GatsbyImage
            className="h-full w-full"
            image={data.file.childImageSharp.gatsbyImageData}
            alt="Cycle"
          />
        </div>
        <div className="lg:col-span-9 pt-10 lg:px-10 lg:py-16">
          <h2 className="text-greenDark text-3xl font-light font-quick tracking-tight pb-5">
            The three year cycle…
          </h2>
          <p className="text-dark">
            It is important to note at the outset that children could only
            benefit fully from the Montessori program if they are allowed to
            follow through the “three year cycle”. This is due to the fact that
            the time and effort spent in learning various subjects – specially
            in language and math.- during the first two years, come together and
            into fruition in the third year.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cycle;
