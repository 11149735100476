import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Childhood = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "childhood.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24 font-light px-4">
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="lg:col-span-8 bg-[#F6F6F6] px-10 py-16 rounded-t-md lg:rounded-l-md">
          <h2 className="text-greenDark text-3xl font-light font-quick tracking-tight pb-5">
            Childhood is a time for fun and learning…
          </h2>
          <p className="text-dark">
            As already mentioned, our Educational Program is based on Dr Maria
            Montessori’s principles of early childhood education and includes
            the following:
          </p>
          <p className="pt-4 text-dark">
            Practical Life Exercises are familiar activities, which the child
            can relate to.
          </p>
          <p className="pt-4 text-dark">
            The Sensorial Materials train the child to observe, make comparisons
            and form accurate judgments.
          </p>
          <p className="pt-4 text-dark">
            The Mathematics Program allows the child to experience mathematical
            relationships and concepts.
          </p>
          <p className="pt-4 text-dark">
            The Language Program develops the child’s communication skills,
            first in oral then in written forms.
          </p>
          <p className="pt-4 text-dark">
            Introduction to French together with Art, Crafts, Drama and Music
            “For Fun” are an integral part of daily classroom activities, as are
            Natural Science, History and Geography.
          </p>
          <p className="pt-4 text-dark">
            Education, both indoors and outdoors, is an on-going feature of the
            daily program.
          </p>
          <p className="pt-4 text-dark">
            Education, both indoors and outdoors, is an on-going feature of the
            daily program.
          </p>
          <p className="pt-4 text-dark">
            The mixed age group provides a real life setting, in which the
            children learn the skills of co-operation and friendship.
          </p>
        </div>
        <div className="lg:col-span-4">
          <GatsbyImage
            className="h-full w-full rounded-b-md lg:rounded-b-none lg:rounded-br-md lg:rounded-tr-md"
            image={data.file.childImageSharp.gatsbyImageData}
            alt="Childhood"
          />
        </div>
      </div>
    </div>
  );
};

export default Childhood;
