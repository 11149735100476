import * as React from "react";

const Montessori = () => {
  return (
    <div className="max-w-screen-xl mx-auto  px-4">
      <div className="font-light border-y border-dark py-12 lg:py-24">
        <div className="px-10 py-16 font-light bg-[#F6F6F6] rounded-md">
          <h2 className="text-greenDark text-3xl font-light font-quick tracking-tight pb-5">
            The Purpose of Montessori Education
          </h2>
          <p className="text-dark">
            The Montessori method of early childhood education is based on
            cultivating each child’s natural desire to learn and experiencing
            the excitement of learning by his/her own choice using Montessori
            materials. Through a program of individualized daily learning,
            children acquire the basic skills of reading, writing and arithmetic
            in the same natural way as they learn to walk and talk. This same
            approach of channeling their natural curiosity, enthusiasm and self-
            motivation into an enjoyable learning experience is applied to
            additional academic activities including natural sciences,
            geography, French introduction, music, arts, crafts and physical
            activities.
          </p>
          <p className="py-5 text-dark">
            Therefore, the materials that the children use and the activities
            that they engage in, help them develop concentration, co-ordination,
            and work habits as necessary tools for more advanced learning and
            development at later stages of their lives. Moreover, independence,
            self confidence, self-esteem, positive and creative thinking will be
            instilled in children as a consequence of the implementation of
            Montessori principles. These habits and skills are the necessary
            foundation for the children’s future success as well as their
            wholesome growth.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Montessori;
