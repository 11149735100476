import * as React from "react";

const Mission = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24 px-4 font-light">
      <p className="text-dark">
        Our mission is to create a happy and nurturing environment for children
        between the ages of 18 months to 6 years old, which is conducive to the
        development of their social, intellectual, academic, physical and
        artistic skills. Moreover, children are encouraged to achieve their full
        potential, discover joy of working and learning, explore and solve
        problems, develop social skills and independence. We are committed to
        provide children with the required individual attention and
        encouragement to learn and explore various subjects at an improving
        pace.
      </p>
      <p className="py-5 text-dark">
        In order to achieve the above goals, our Casa program is based on the
        Montessori method of early childhood education for children between the
        ages of 2 1/2 to 6 years old. Our “Toddler” program (ages 1 1/2 up to 3
        years old), however, is based on the “Early Childhood Method of
        Education” and focused on developing and enriching Toddlers’ vocabulary
        as well as eye and hand co-ordinations, social skills, fine and gross
        motor activities, arts and craft, etc. This is a preparatory program,
        primarily for younger siblings of our Casa students as well as for those
        children who would be moving on to our Casa program when they reach the
        ages of 2 1/2 to 3 years old. Our “Toddlers” will have fun-packed,
        creative and educational programs on a daily basis.
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-12 pt-10">
        <div className="lg:col-span-8 bg-[#F6F6F6] px-10 py-16 lg:rounded-l-md">
          <h2 className="text-greenDark text-3xl font-light font-quick tracking-tight pb-5">
            FGMP School Handbook – Policies & Guidelines
          </h2>
          <div className="font-light">
            <a
              href="/FGMS-Handbook.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-greenDark pr-1"
            >
              Click Here
            </a>
            <span className="text-dark font-light">
              to download the Schools Handbook
            </span>
          </div>
        </div>
        <div className="lg:col-span-4 bg-[#A6A4A4] lg:rounded-r-md"></div>
      </div>
    </div>
  );
};

export default Mission;
